import {Component, OnInit} from '@angular/core';
import {SideNavComponent} from '../../../modules/layout/side-nav/side-nav.component'; 

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    const hamb = document.getElementById('hamburguer'); 
    const menu = document.querySelector(".main-sidebar");
    const abre = document.querySelector(".aberto");
    const fecha = document.querySelector(".fechado");
    
    hamb.addEventListener("click", function () {
    menu.classList.toggle("abre");
    abre.classList.toggle("fecha-hamb");
    fecha.classList.toggle("abre-hamb");
    });

    menu.addEventListener("click", function () {
      menu.classList.toggle("abre");
      abre.classList.toggle("fecha-hamb");
      fecha.classList.toggle("abre-hamb");
    });
  }
    
    
  
  
  
  
}
