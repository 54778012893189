import { Component, OnInit } from '@angular/core';
import { DashBoardComponent } from '../../pages/pages/dash-board/dash-board.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SocioService } from 'src/app/services/socio.service';
import util from '../../../util';



declare var $;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  user: any;
  imagemperfil = util.storage+'perfil/Silhueta-png-cinzento-300x284.png'

  constructor(
    private router: Router,
    private userservice: UserService,
    private socioService: SocioService,

  ) {
  }
  menu = [
  ];
  teste(){
    console.log('teste')
  }
  ngOnInit() { 
    const id = sessionStorage.getItem('usuario')
    console.log(id)
    if(!id){
      this.router.navigate(['/']);
    }
    this.userservice.getusuariosunico(id).then((res)=>{
      this.user = res.body;
      if(this.user.foto){
        this.imagemperfil  = util.storage+this.user.foto;
      }

// console.log(res.body)
if (res.body.grupo_id != 2 && res.body.grupo_id != 7) {
  this.socioService.verificarSocio(id).then((r)=>{
      if (r.body) {
        this.menu = [
          { titulo: 'Início', link: '/Main/Home', icone: 'assets/dist/img/Icon/icon inicio.png' },
          { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/iconeperfil.png' },
          { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/iconassociação.png' },
          // { titulo: 'Lista de associados', link: '/Main/Lista-socio', icone: 'assets/dist/img/Icon/listadeassociadosicon.png' },
          { titulo: 'Acompanhar pagamento', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/acompanharpagaentoicon.png' },
          // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' }, 
          // { titulo: 'Inscrição', link: '/Main/Em-breve', icone: '' },
          // { titulo: 'Empenho', link: '/Main/empenho', icone: '' },
          // { titulo: 'Certificados', link: '/Main/Em-breve', icone: '' },
      
        ]; 
      } else {
        this.menu = [
          { titulo: 'Início', link: '/Main/Home', icone:'assets/dist/img/Icon/icon inicio.png'},
          { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/iconeperfil.png' },
          { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/iconassociação.png' },
          { titulo: 'Acompanhar pagamento', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/acompanharpagaentoicon.png' },
          // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' },
          // { titulo: 'Inscrição', link: '/Main/Em-breve', icone: '' },
          // { titulo: 'Convite para o jantar', link: '/Main/Em-breve', icone: '' },
          // { titulo: 'Certificados', link: '/Main/Em-breve', icone: '' },
      
        ];  
      }
  })

} else {
  this.menu = [
    { titulo: 'Início', link: '/Main/Home', icone: 'assets/dist/img/Icon/icon inicio.png' },
    { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/iconeperfil.png' },
    { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/iconassociação.png' },
    { titulo: 'Financeiro', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/acompanharpagaentoicon.png' },
    { titulo: 'Lista de associados', link: '/Main/Lista-socio', icone: 'assets/dist/img/Icon/listadeassociadosicon.png' },
    { titulo: 'Lista de usuários', link: '/Main/Listar-usuario', icone: 'assets/dist/img/Icon/listadeusuarios.png' },
    // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' },
    // { titulo: 'Inscrição', link: '/Main/inscricao', icone: '' },
    // { titulo: 'Lista de Inscritos', link: '/Main/Lista-inscrito', icone: '' },
    // { titulo: 'Empenho', link: '/Main/empenho', icone: '' },
    // { titulo: 'Certificados', link: '/Main/Em-breve', icone: '' },
    // { titulo: 'Inscrição Teste', link: '/Main/inscricao-teste', icone: '' },

 

  ];
  
}
   
     }).catch((err)=>{ 
       console.log(err)
     })

    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
  }
  navigate(rota) {
    this.router.navigate([rota]);
  }
  logout(){
    sessionStorage.setItem('usuario','')
    this.router.navigate(['/'])
  }

}
