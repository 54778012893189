import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-basico',
  templateUrl: './modal-basico.component.html',
  styleUrls: ['./modal-basico.component.scss']
})
export class ModalBasicoComponent {
  confirme = new EventEmitter();
  ok:boolean;
  btncancelar:boolean;
  constructor(
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    onAdd = new EventEmitter();
    ngOnInit(){
      this.btncancelar = !this.data.buttonCancelar;
      this.ok = !this.data.buttonOK;
    }
  onNoClick(): void {
    this.onAdd.emit();
    this.dialogRef.close();
  }
  cancelar(){
    this.dialogRef.close();
  }

}
