import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { Http } from '@angular/http';
import { SideNavComponent } from '../modules/layout/side-nav/side-nav.component';
import util from '../util';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  urlAPI = util.api; // api rest fake
  constructor(
    private httpClient: HttpClient,
    ) { }
    @Output()

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  salvarusuario(form) {
    let res = this.httpClient.post<User>(this.urlAPI + 'register',form)
    .toPromise().then((res)=>{

      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  salvarusuarioBypass(form) {
    let res = this.httpClient.post<User>(this.urlAPI + 'register/bypass',form)
    .toPromise().then((res)=>{

      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  getusuarios() {
   let res = this.httpClient.get<User>(this.urlAPI + 'usuarioAdm',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
    return res
  }
  getusuariosunico(id){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'usuario/grupo/'+id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  alterarusuario(id,form){
    let res = this.httpClient.post<User>(this.urlAPI + 'usuario/alterar/'+id,form)
    .toPromise().then((res)=>{

      return res
    }).catch((err)=>{
      return err
    })
        return res
    
  }
  alterardadoscomplementares(id,form){
    console.log(form);
    let res = this.httpClient.post<User>(this.urlAPI + 'dadoscomplementares/alterar/'+id,form)
    .toPromise().then((res)=>{

      return res
    }).catch((err)=>{
      return err
    })
        return res
    
  }
  subirDocument(id,fd){
    let res = this.httpClient.post<User>(this.urlAPI + 'usuario/comprovante/'+id,fd)
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  subirfotoPerfil(id,fd){
    let res = this.httpClient.post<User>(this.urlAPI + 'usuario/foto/'+id,fd)
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  RecusarDocumento(id,env){
    let res = this.httpClient.post<User>(this.urlAPI + 'usuario/docrecusado/'+id,env)
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  AprovarDocumento(id,env){
    let res = this.httpClient.post<User>(this.urlAPI + 'usuario/docaprovada/'+id,env)
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
