import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { Http } from '@angular/http';
import util from '../util';

@Injectable({
  providedIn: 'root'
})
export class TipoInscricaoService {
  urlAPI = util.api; // api rest fake
  
  constructor(
    private httpClient: HttpClient,
    ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  GetTipodeInscricao(){    
    let res = this.httpClient.get<User>(this.urlAPI + 'TipodeInscricao/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  ValorAssociacao(){
    let res = this.httpClient.get<User>(this.urlAPI + 'tedass/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }

  ValorInscricao(){

    let res = this.httpClient.get<User>(this.urlAPI + 'preco/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res



  }
  
  
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

